<template>
  <div class="bc-500">
    <div class="bc-500__title">{{ $t('ERRORS.500.TITLE') }}</div>
    <div class="bc-500__subtitle">{{ $t('ERRORS.500.SUBTITLE') }}
    </div>
    <div class="bc-500__guide">{{ $t('ERRORS.500.BTN_TITLE') }}</div>
    <div class="bc-500__btn">
      <bc-button :color-type="'white'" @click="$router.push('/')">{{ $t('ERRORS.500.BTN') }}</bc-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "bc-500",
  components: {}

}
</script>

<style scoped lang="scss">
.bc-500 {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-width: 780px;
  padding: 20px;

  &__title {
    font-size: 2em;
    margin-bottom: 30px;
  }

  &__subtitle {
    text-align: center;
    font-size: .8em;
    margin-bottom: 50px;
  }

  &__guide {
    color: $dark-gray;
    font-size: .75em;
    margin-bottom: 30px;
  }

  &__btn {
    display: flex;

    button {
      margin: 0 20px;
    }
  }

}
</style>